/* -----------------------------------------------------------------------------
 *
 * Fonts: Montserrat
 *
 * ----------------------------------------------------------------------------- */

/* -----------------------------------------------------------------------------
 * Thin
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  src: '/fonts/Montserrat/Montserrat-Thin.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  src: '/fonts/Montserrat/Montserrat-ThinItalic.ttf';
  font-display: swap;
}

/* -----------------------------------------------------------------------------
 * Extra Light
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: '/fonts/Montserrat/Montserrat-ExtraLight.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 200;
  src: '/fonts/Montserrat/Montserrat-ExtraLightItalic.ttf';
  font-display: swap;
}

/* -----------------------------------------------------------------------------
 * Light
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: '/fonts/Montserrat/Montserrat-Light.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 300;
  src: '/fonts/Montserrat/Montserrat-LightItalic.ttf';
  font-display: swap;
}

/* -----------------------------------------------------------------------------
 * Regular
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: '/fonts/Montserrat/Montserrat-Regular.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: '/fonts/Montserrat/Montserrat-Italic.ttf';
  font-display: swap;
}

/* -----------------------------------------------------------------------------
 * Medium
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: '/fonts/Montserrat/Montserrat-Medium.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: '/fonts/Montserrat/Montserrat-MediumItalic.ttf';
  font-display: swap;
}

/* -----------------------------------------------------------------------------
 * Semi-Bold
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: '/fonts/Montserrat/Montserrat-SemiBold.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: '/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf';
  font-display: swap;
}

/* -----------------------------------------------------------------------------
 * Bold
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: '/fonts/Montserrat/Montserrat-Bold.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: '/fonts/Montserrat/Montserrat-BoldItalic.ttf';
  font-display: swap;
}

/* -----------------------------------------------------------------------------
 * Extra Bold
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: '/fonts/Montserrat/Montserrat-ExtraBold.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: '/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf';
  font-display: swap;
}


/* -----------------------------------------------------------------------------
 * Black
 * ----------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: '/fonts/Montserrat/Montserrat-Black.ttf';
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: '/fonts/Montserrat/Montserrat-BlackItalic.ttf';
  font-display: swap;
}
