/* -----------------------------------------------------------------------------
 *
 * Templates: Landing
 *
 * ----------------------------------------------------------------------------- */

@import '../../styles/common.css';

.title {
  @mixin screen-reader-text;
}
