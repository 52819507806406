/* -----------------------------------------------------------------------------
 *
 * Molecules: Site Navigation
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

.siteNavigation {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.logo {
  display: block;
  position: relative;
  margin: 0 auto;
}
