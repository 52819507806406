/* -----------------------------------------------------------------------------
 *
 * Utilities: Utilities
 *
 * ----------------------------------------------------------------------------- */

@define-mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

@define-mixin outline {
  /* outline: 2px solid var( --color-primary );
  outline-offset: 4px;
  outline-style: dashed; */
}

@define-mixin screen-reader-text {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect( 1px, 1px, 1px, 1px );

  &:focus {
    display: block;
    z-index: 100000;
    top: 4px;
    left: 4px;
    width: auto;
    height: auto;
    padding: 8px;
    clip: auto !important;
    border-radius: 4px;
    background-color: var( --color-white );
    box-shadow: 0 0 2px 2px color-mod( var( --color-black ) a( 0.5 ) );
    color: var( --color-black );
    font-size: rem( 14px );
    font-weight: bolder;
    line-height: lh( 14px, 24px );
    text-decoration: none;
  }
}
