/* -----------------------------------------------------------------------------
 *
 * Organisms: Footer
 *
 * ----------------------------------------------------------------------------- */

.footer {
  margin-top: 32px;
  padding: 32px 0;
  background-color: var( --color-mine-shaft-1 );

  @media (--medium) {
    padding: 24px 0;
  }
}
