/* -----------------------------------------------------------------------------
 *
 * Organisms: FooterNavigation
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

/* -----------------------------------------------------------------------------
 * Info
 * ----------------------------------------------------------------------------- */

.info {
  justify-content: space-between;

  @media (--medium) {
    display: flex;
    flex-flow: row;
  }
}

/* -----------------------------------------------------------------------------
 * Info Left
 * ----------------------------------------------------------------------------- */

.infoLeft {
  display: flex;
  flex-flow: column;

  @media (--medium) {
    flex-flow: row;
  }
}

/* -----------------------------------------------------------------------------
 * Info Right
 * ----------------------------------------------------------------------------- */

.infoRight {
  margin-top: 32px;
  padding-top: 12px;
  border-top: 1px solid var( --color-mercury );

  @media (--medium) {
    margin: 0;
    padding: 0;
    border: 0;
  }
}

/* -----------------------------------------------------------------------------
 * Copyright
 * ----------------------------------------------------------------------------- */

.copyright {

}

/* -----------------------------------------------------------------------------
 * Info Item
 * ----------------------------------------------------------------------------- */

.infoItem {
  position: relative;
  margin-bottom: 8px;
  padding-right: 8px;

  @media (--medium) {
    margin-right: 8px;
    margin-bottom: 0;
  }
}

.infoItem::after {

  @media (--medium) {
    content: '|';
    padding-left: 16px;
  }
}

.infoItem:last-of-type::after {
  content: none;
}

/* -----------------------------------------------------------------------------
 * Name
 * ----------------------------------------------------------------------------- */

.name {
  margin-left: 8px;
}

/* -----------------------------------------------------------------------------
 * Link
 * ----------------------------------------------------------------------------- */

.link {
  margin-left: 8px;
  color: var( --color-mercury );
  text-decoration: none;
}

.link:hover,
.link:focus {
  color: var( --color-cyan-1 );
  text-decoration: underline;
}
