/* -----------------------------------------------------------------------------
 *
 * Organisms: Header
 *
 * ----------------------------------------------------------------------------- */

.header {
  margin-bottom: 30px;
  padding-top: 60px;
}
