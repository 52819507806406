/* -----------------------------------------------------------------------------
 *
 * Typography: Paragraph
 *
 * ----------------------------------------------------------------------------- */

.paragraph {
  margin-top: 0;
  margin-bottom: 20px;
}

* > .paragraph:last-of-type {
  margin-bottom: 0;
}
