/* -----------------------------------------------------------------------------
 *
 * Molecules: Section
 *
 * ----------------------------------------------------------------------------- */

.section {
  margin-bottom: 60px;
}

* > .section:last-of-type {
  margin-bottom: 0;
}
